/*video initial play button restyled*/
.video-react .video-react-big-play-button {
    background-color: rgba(0,0,0,.6);
    border: transparent;
    border-radius: 50%;
    font-size: 4.5em;
    line-height: 80px;
    width: 80px;
    height: 80px;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
}